<template>
  <article class="vehicle-offer">
    <h6 class="vehicle-offer__title">
      {{ title }}
    </h6>
    <p>
      {{ body + (body.endsWith('.') ? '' : '.') }}
      <NuxtLink
        class="vehicle-offer__body"
        to="/page/offers-terms-conditions"
        target="_blank"
        >T&amp;Cs apply</NuxtLink
      >
    </p>
  </article>
</template>

<script lang="ts">
export default {
  name: 'VehicleOffer',

  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.vehicle-offer {
  background: $grey--lightest;
  padding: rem(16);
  border-radius: $radius;
  color: $grey--darkest;
}
.vehicle-offer__title {
  font-family: $mb-font-text-bold;
  font-size: rem(18);
  margin-bottom: rem(4);
  line-height: 1.4;
}
.vehicle-offer__body {
  font-family: $mb-font-text-bold;
  font-size: rem(14);
  text-decoration: none;
  color: $blue--dark;
}
</style>
