<template>
  <div class="vehicle-offers">
    <VehicleOffer
      v-for="offer in monetaryOffers"
      :key="offer.Id"
      :title="monetaryOfferTitle(offer)"
      :body="monetaryOfferBody(offer)"
    />
    <VehicleOffer
      v-for="financeOffer in financeOffers"
      :key="financeOffer.Id"
      :title="financeOffer.Value"
      :body="financeOffer.TermsAndConditions"
    />
    <VehicleOffer
      v-for="offer in nonMonetaryOffers"
      :key="offer.Id"
      :title="offer.OfferDescription"
      :body="nonMonetaryBody(offer)"
    />
  </div>
</template>

<script lang="ts">
import { FinanceCampaignMessage, ICampaignOffer } from '~/types/vehicle'

export default {
  name: 'VehicleOffers',

  props: {
    nonMonetaryOffers: {
      type: Array as PropType<ICampaignOffer[]>,
      required: false,
      default: () => []
    },
    monetaryOffers: {
      type: Array as PropType<ICampaignOffer[]>,
      required: false,
      default: () => []
    },
    financeOffers: {
      type: Array as PropType<FinanceCampaignMessage[]>,
      required: false,
      default: () => []
    }
  },

  setup() {
    const nonMonetaryBody = (offer: ICampaignOffer) => {
      const { OfferValueGross, OfferType, To } = offer

      const offerValue = OfferValueGross
        ? `This offer is worth £${OfferValueGross}. `
        : ''

      const offerTypeMap: Record<string, string> = {
        Service: 'Servicing offer',
        Warranty: 'Warranty offer'
      }

      const offerType = offerTypeMap[OfferType] || 'Offer'

      const expiresAt = `expires ${To}.`

      return `${offerValue}${offerType} ${expiresAt}`
    }

    const monetaryOfferBody = (offer: ICampaignOffer) =>
      `Get this offer if you order by ${offer.To}`

    const { formatDate } = useDate()

    const monetaryOfferTitle = (offer: ICampaignOffer) => {
      return `${formatGBP(offer.Value)} ${offer.CampaignOLEVGrant ? 'OLEZ grant' : 'offer'} until ${formatDate(offer.To, 'Do MMMM YYYY', 'DD/MM/YYYY')}`
    }

    return {
      monetaryOfferTitle,
      monetaryOfferBody,
      nonMonetaryBody
    }
  }
}
</script>

<style lang="scss">
.vehicle-offers {
  display: grid;
  row-gap: rem(24);
}
</style>
